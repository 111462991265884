


































import Vue from "vue";
import { Component, } from "vue-property-decorator";

import Slick from "vue-slick";
import 'slick-carousel/slick/slick.css';
import ProductCard from "@/components/ProductCard.vue";
import Carrousel from "@/components/Carrousel.vue";

import {
  HomeClient,
  HomeDto,
  TopSellingProductsDto,
} from "../../base/news-soft-ecommerce-client";

@Component({
  components: {
    Slick,
    ProductCard,
    Carrousel,
  },
})

export default class Home extends Vue {
  slickOptions: any = {
    rows: 0,
    dots: true,
    infinite: true,
    speed: 300,
    arrows: true,
    pauseOnHover: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: false,
    autoplaySpeed: 5000,
    adaptativeHeight: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: true,
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  imgList = [
    {
      imgSrc: 'https://picsum.photos/1096/320/?image=52',
      imgCaption: 'Teste'
    },
    {
      imgSrc: 'https://picsum.photos/1096/320/?image=53',
      imgText: 'Teste teste teste'
    }
  ]

  homeClient: HomeClient = new HomeClient();

  homeDto: HomeDto = new HomeDto();

  async created() {
    this.homeDto = await this.homeClient.get(null);
  }
}
